import React from 'react';
import { makeStyles } from '@mui/styles';
import { colors } from '../../theme';
import QAListItem from '../QAListItem';
import { MAX_WIDTH } from '../../constants';

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: colors.black,
  },
  content: {
    maxWidth: MAX_WIDTH,
    height: '100%',
    margin: 'auto',
    padding: '90px 64px 108px',
    [theme.breakpoints.up('mobile')]: {
      padding: '32px 41px',
    },
    [theme.breakpoints.up('tablet')]: {
      padding: '60px 41px',
    },
  },
}));

function Footer() {
  const classes = useStyles();

  return (
    <div className={classes.footer}>
      <div className={classes.content}>
        <QAListItem index={1}>
          <p className="heading">How your assets can grow?</p>
          <p className="text">
            Investing in securities involves risks, and there is always the
            potential of losing money when you invest in securities. Before
            investing, consider your investment objectives and Simplewealth AG
            charges and expenses.
          </p>
        </QAListItem>
        <QAListItem index={2}>
          <p className="heading">
            All your investments are insured. Up to USD 500k
          </p>
          <p className="text">
            Client securities accounts at Interactive Brokers LLC are protected
            by the Securities Investor Protection Corporation (“SIPC”) for a
            maximum coverage of $500,000 (with a cash sublimit of $250,000) and
            under Interactive Brokers LLC’s excess SIPC policy with certain
            underwriters at Lloyd’s of London 1 for up to an additional $30
            million (with a cash sublimit of $900,000) subject to an aggregate
            limit of $150 million. Futures and options on futures are not
            covered. As with all securities firms, this coverage provides
            protection against failure of a broker-dealer, not against loss of
            market value of securities. For the purpose of determining an
            Interactive Brokers LLC client account, accounts with like names and
            titles (e.g. John and Jane Smith and Jane and John Smith) are
            combined, but accounts with different titles are not (e.g.
            Individual/John Smith and IRA/John Smith).SIPC is a non-profit,
            membership corporation funded by broker-dealers that are members of
            SIPC.
          </p>
        </QAListItem>
      </div>
    </div>
  );
}

export default Footer;
