import { styled } from '@mui/material';
import { colors } from '../../theme';
import { MAX_WIDTH } from '../../constants';

const Wrapper = styled('section')`
  background-color: ${colors.white};
`;

const Content = styled('div')`
  max-width: ${MAX_WIDTH}px;
  margin: auto;
  height: 100%;

  padding: 50px 63px 64px 65px;
  display: flex;
  
`;

export default { Wrapper, Content };
