export const actionTypes = {
  SET_OVERVIEW_DATA: 'SET_OVERVIEW_DATA',
};

export default function overviewReducer(state, action) {
  switch (action.type) {
    case actionTypes.SET_OVERVIEW_DATA:
      return {
        ...state,
        ...action.payload,
      };
    default: return state;
  }
}
