import React from 'react';
import Paper from '@mui/material/Paper';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Form } from 'react-final-form';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import CS from '../common/styled';
import { FONTS } from '../../constants';
import { colors } from '../../theme';
import useValidationSchema from '../../hooks/useValidationSchema';
import LOGIN_SCHEMA from '../../schemas/login.yup';
import FPasswordInput from '../FormInput/FPasswordInput';
import FTextInput from '../FormInput/FTextInput';

const useStyles = makeStyles(() => ({
  paper: {
    padding: ((props) => (props.mobile ? '36px 32px 32px' : '76px 96px 92px')),
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 608,
    borderRadius: ((props) => (props.mobile ? 0 : 24)),
    position: 'relative',
    minHeight: ((props) => (props.mobile ? 'calc(100vh - 74px)' : 'initial')),
  },
  heading: {
    font: `normal normal 500 33px/42px ${FONTS}`,
    letterSpacing: -0.8,
    marginBottom: 31,
  },
  input: {
    minWidth: ((props) => (props.mobile ? 310 : 416)),
    marginBottom: 31,
  },
  button: {
    font: `normal normal 500 16px/20px ${FONTS}`,
    marginBottom: 32,
  },
  close: {
    position: 'absolute',
    top: 38,
    right: 38,
    display: (props) => (props.mobile ? 'none' : 'block'),
  },
  or: {
    color: colors.silver,
    font: `normal normal 400 14px/17.5px ${FONTS}`,
    marginLeft: 16,
    marginRight: 16,
  },
  alternative: {
    width: '100%',
    display: 'inline-flex',
    justifyContent: 'center',
  },
  link: {
    '&:hover': {
      borderBottom: '1px solid',
    },
  },
}));

const initialValues = {
  email: '',
  password: '',
};

function Login({ handleSubmit }) {
  const validate = useValidationSchema(LOGIN_SCHEMA);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.tablet));
  const classes = useStyles({ mobile: isMobile });

  return (
    <CS.ContentWrapper mobile={isMobile}>
      <Paper elevation={0} className={classes.paper}>
        <Typography component="h1" className={classes.heading}>Login</Typography>
        <Form onSubmit={handleSubmit} initialValues={initialValues} validate={validate}>
          {(props) => (
            <form onSubmit={props.handleSubmit}>
              <FTextInput
                name="email"
                label="Email"
                className={classes.input}
                inputProps={{ type: 'email', placeholder: 'Enter your email' }}
              />
              <FPasswordInput
                name="password"
                label="Create password"
                className={classes.input}
                inputProps={{ placeholder: 'Enter password' }}
              />
              <Button
                className={classes.button}
                color="primary"
                variant="contained"
                type="submit"
                fullWidth
              >
                Login
              </Button>
              <span className={classes.alternative}>
                <CS.InternalLink className={classes.link} to="/restore-password">
                  Restore password
                </CS.InternalLink>
                <span className={classes.or}>or</span>
                <CS.InternalLink className={classes.link} to="/registration">
                  Create account
                </CS.InternalLink>
              </span>
            </form>
          )}
        </Form>
      </Paper>
    </CS.ContentWrapper>
  );
}

export default Login;
