import dayjs from 'dayjs';

// e.g. input === '202003'
export const transformToDate = (input) => dayjs(input).format('YYYY, MMM');

export const mergeObjects = (objects) => {
  const merged = {};

  objects.forEach((obj) => {
    Object.keys(obj).forEach((key) => {
      merged[key] = { ...merged[key], ...obj[key] };
    });
  });

  return merged;
};

export const filterObjProps = (obj, filterFn) => {
  const filtered = {};

  Object.keys(obj).forEach((key) => {
    if (filterFn(obj[key])) {
      filtered[key] = obj[key];
    }
  });

  return filtered;
};

export const hasProp = (obj, prop) => Object.hasOwnProperty.call(obj, prop);

export const checkToken = () => document.cookie.includes('token');

export const deleteCookie = (name) => {
  document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
};
