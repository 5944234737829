import React from 'react';
import S from './styled';

function Background({ children, ...rest }) {
  return (
    <S.Background {...rest}>{children}</S.Background>
  );
}

export default Background;
