import React from 'react';
import {
  node, number, oneOfType, string,
} from 'prop-types';
import { makeStyles } from '@mui/styles';
import { FONTS } from '../../constants';
import { colors } from '../../theme';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: 'relative',

    '&:not(:last-child)': {
      marginBottom: 32,
    },

    '& .heading': {
      marginTop: 0,
      marginBottom: 16,
      font: `normal normal 500 17px/22.5px ${FONTS}`,
      letterSpacing: 0,
      color: colors.white,
    },
    '& .text': {
      marginTop: 0,
      marginBottom: 0,
      font: `normal normal 500 13px/16px ${FONTS}`,
      letterSpacing: 0,
      color: theme.palette.secondary.main,
    },
  },
  index: {
    position: 'absolute',
    top: -5,
    left: 0,
    color: theme.palette.secondary.main,
    font: `normal normal 400 9px/18px ${FONTS}`,
    letterSpacing: 0,
    marginLeft: -31,
  },
}));

function QAListItem({ index, children }) {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <span className={classes.index}>{index}</span>
      {children}
    </div>
  );
}

QAListItem.propTypes = {
  index: oneOfType([string, number]),
  children: node,
};

export default QAListItem;
