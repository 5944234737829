import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useOnLocationChange from '../hooks/useOnLocationChange';
import { checkToken, deleteCookie } from '../utils';

export const AuthorizationContext = React.createContext({
  isAuth: false,
  setAuth: () => {},
  logout: () => {},
});

export default function AuthorizationProvider({ children }) {
  const [isAuth, setAuth] = useState(false);
  const isAuthenticated = useOnLocationChange(checkToken);
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || 'overview';

  const handleLogout = useCallback(() => {
    setAuth(false);
    deleteCookie('token');
  }, []);

  useEffect(() => {
    setAuth(isAuthenticated);
    navigate(from, { replace: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  const authData = useMemo(
    () => ({
      isAuth,
      setAuth,
      logout: handleLogout,
    }),
    [handleLogout, isAuth],
  );

  return (
    <AuthorizationContext.Provider value={authData}>
      {children}
    </AuthorizationContext.Provider>
  );
}

export function useAuthorizationContext() {
  const authorizationContext = React.useContext(AuthorizationContext);
  if (!authorizationContext) {
    throw new Error(
      'useAuthorizationContext must be used within a AuthorizationProvider',
    );
  }
  return authorizationContext;
}
