import React, { useCallback } from 'react';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import S from './styled';
import NavSection from '../NavSection';
import { ReactComponent as Logo } from '../../assets/icons/logo.svg';
import { FONTS } from '../../constants';
import { useAuthorization } from '../../contexts';

const useStyles = makeStyles((theme) => ({
  button: {
    display: 'block',
    height: 42,
    font: `normal normal 400 14px/16px ${FONTS}`,
    [theme.breakpoints.up('mobile')]: {
      minWidth: '100%',
    },
    [theme.breakpoints.up('tablet')]: {
      minWidth: 176,
    },
    [theme.breakpoints.up('desktop')]: {
      minWidth: 115,
    },
  },
  navSection: {
 
    '& > a:not(:last-of-type), & > span:not(:last-of-type)': {
      marginBottom: 16,
    },
    '& > button:not(:last-of-type)': {
      marginBottom: 8,
    },
    [theme.breakpoints.up('tabletMin')]: {
      gridRow: '2',
    },
    [theme.breakpoints.up('desktop')]: {
      gridRow: '1',
    },
  },
  a: {
    maxHeight: 30,
    maxWidth: 130,
  },
  content: {
    maxWidth: '1440px',
    height: '100%',
    margin: 'auto',
    display: 'grid',
    gridTemplateColumns: 'repeat(6, 192px)',
    columnGap: '32px',
    [theme.breakpoints.up('mobile')]: {
      gridTemplateColumns: '1fr',
      gap: 61,
      padding: '66px 41px',
    },
    [theme.breakpoints.up('tabletMin')]: {
      gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
      gap: 31,
      rowGap: 60,
      padding: '66px 41px',
    },
    [theme.breakpoints.up('desktop')]: {
      gridTemplateColumns: '4fr 2fr 1fr 3fr 1fr',
      flexDirection: 'row',
      padding: '66px 63px 50px 65px',
    },
    "& > a:first-child":{
      [theme.breakpoints.up('mobile')]: {
        marginBottom: 120,
      },
      [theme.breakpoints.up('tabletMin')]: {
        marginBottom: 32,
      },
      [theme.breakpoints.up('desktop')]: {
       gridRow: 1
      },
    },
    '& > div:nth-child(2)': {
      [theme.breakpoints.up('tabletMin')]: {
        paddingLeft: '20px',
      },
      [theme.breakpoints.up('tablet')]: {
        padding: '0',
      },
    },
    '& > div:last-child': {
      '& p': {
        [theme.breakpoints.up('mobile')]: {
          display: 'none',
        },
        [theme.breakpoints.up('tabletMin')]: {
          display: 'block',
        },
      },
      [theme.breakpoints.up('mobile')]: {
        gridRow: '1',
        marginBottom: 0
      },
      [theme.breakpoints.up('tabletMin')]: {
        gridRow: '1',
        gridColumn: '4/6',
      },
      [theme.breakpoints.up('desktop')]: {
        gridRow: '1',
        gridColumn: "5"
      },
    },
  },
}));

function BottomNavigation() {
  const classes = useStyles();
  const navigate = useNavigate();

  const { isAuth } = useAuthorization();

  const handleNavigate = useCallback(
    (path) => () => {
      navigate(path);
    },
    [navigate],
  );

  return (
    <S.Wrapper>
      <div className={classes.content}>
        <a className={classes.a} href="./" rel="noopener noreferrer">
          <NavSection className={classes.navSection} label={<Logo />} />
        </a>
        <NavSection className={classes.navSection} label="Navigation">
          <S.ExternalNavigationLink
            target="_blank"
            href="https://www.simplewealth.ch/how-to/"
          >
            How To Start
          </S.ExternalNavigationLink>
          <S.ExternalNavigationLink
            target="_blank"
            href="https://www.simplewealth.ch/fees/"
          >
            Our plans & pricing
          </S.ExternalNavigationLink>
          <S.ExternalNavigationLink
            target="_blank"
            href="https://www.simplewealth.ch/press/"
          >
            Media & Press
          </S.ExternalNavigationLink>
          <S.ExternalNavigationLink
            target="_blank"
            href="https://www.simplewealth.ch/about-us/"
          >
            About Us
          </S.ExternalNavigationLink>
          <S.ExternalNavigationLink
            target="_blank"
            href="https://www.simplewealth.ch/insights/"
          >
            Insights
          </S.ExternalNavigationLink>
          <S.ExternalNavigationLink
            target="_blank"
            href="https://www.simplewealth.ch/faq/"
          >
            Answers
          </S.ExternalNavigationLink>
        </NavSection>
        <NavSection className={classes.navSection} label="Social">
          <S.ExternalNavigationLink
            target="_blank"
            href="https://www.facebook.com/Simplewealth"
          >
            Facebook
          </S.ExternalNavigationLink>
          <S.ExternalNavigationLink
            target="_blank"
            href="https://twitter.com/SimpleWealth"
          >
            Twitter
          </S.ExternalNavigationLink>
          <S.ExternalNavigationLink
            target="_blank"
            href="https://www.linkedin.com/company/simplewealth-ag"
          >
            Linkedin
          </S.ExternalNavigationLink>
        </NavSection>
        <NavSection className={classes.navSection} label="Documents">
          <S.ExternalNavigationLink
            target="_blank"
            href="https://www.simplewealth.ch/policy/"
          >
            Privacy Policy
          </S.ExternalNavigationLink>
          <S.ExternalNavigationLink
            target="_blank"
            href="https://www.simplewealth.ch/terms/"
          >
            Terms of use
          </S.ExternalNavigationLink>
          <S.ExternalNavigationLink
            target="_blank"
            href="https://www.simplewealth.ch/impressum/"
          >
            Imprint
          </S.ExternalNavigationLink>
        </NavSection>
        <NavSection
          className={classes.navSection}
          label={isAuth ? '' : 'Authorization'}
        >
          {!isAuth && (
            <>
              <Button
                className={classes.button}
                onClick={handleNavigate('/login')}
                variant="contained"
                color="secondary"
              >
                Sign In
              </Button>
              <Button
                className={classes.button}
                onClick={handleNavigate('/registration')}
                variant="contained"
                color="primary"
              >
                Registration
              </Button>
            </>
          )}
        </NavSection>
      </div>
    </S.Wrapper>
  );
}

export default BottomNavigation;
