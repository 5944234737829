import React, { useCallback, useMemo, useState } from 'react';
import API_SERVICE, { getOnboardingComplited } from '../services/api';
import { CURRENT_USER } from '../connectors';
// import { useAuthorization } from './index';

export const UserContext = React.createContext({
  user: null,
  getUserData: () => {},
  clearUserData: () => {},
});

export default function UserProvider({ children }) {
  const [userInfo, setUserInfo] = useState(null);

  // const { isAuth } = useAuthorization();

  const handleClearUserData = useCallback(() => {
    setUserInfo(null);
  }, []);

  const handleGetUserData = useCallback(() => {
    API_SERVICE.currentUser().then((response) => {
      if (response.data?.onboardingID) {
        getOnboardingComplited(response.data?.onboardingID)
          .then((responseOnboarding) => {
            setUserInfo((prev) => {
              const temp = {
                ...prev,
                onboardingComplited: responseOnboarding.data?.data?.completed,
              };
              return temp;
            });
          })
          .catch(() => {
            setUserInfo((prev) => {
              const temp = {
                ...prev,
                onboardingComplited: 0,
              };
              return temp;
            });
          });
      }
      setUserInfo((prev) => {
        const data = CURRENT_USER(response.data);
        const temp = { ...prev, ...data };
        return temp;
      });
    });
  }, []);

  const userMetaData = useMemo(
    () => ({
      user: userInfo,
      getUserData: handleGetUserData,
      clearUserData: handleClearUserData,
    }),
    [handleClearUserData, handleGetUserData, userInfo],
  );

  return (
    <UserContext.Provider value={userMetaData}>{children}</UserContext.Provider>
  );
}

export function useUserContext() {
  const userContext = React.useContext(UserContext);
  if (!userContext) {
    throw new Error('useUserContext must be used within a UserProvider');
  }
  return userContext;
}
