import { useLocation, Navigate, Outlet } from 'react-router-dom';
import React from 'react';
import useDisable from '../../hooks/useDisable';

function RequireDashboard() {
  const location = useLocation();
  const { isDisable } = useDisable();

  return isDisable ? (
    <Navigate to="onboarding" state={{ from: location }} replace />
  ) : (
    <Outlet />
  );
}

export default RequireDashboard;
