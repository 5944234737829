import { styled, css } from '@mui/material';
import theme from '../../theme';
import { FONTS } from '../../constants';
import { ReactComponent as ErrorIcon } from '../../assets/icons/error.svg';

const ErrorWrapper = styled('div')`
  position: relative;
`;

const Error = styled('span')`
  position: absolute;
  bottom: -20px;
  left: 0;
  color: ${theme.palette.error.main};
  font: normal normal 400 11px/14px ${FONTS};
  letter-spacing: 0;

  ${(props) => {
    if (props.position) return css(props.position);
    return css``;
  }}
`;

const ErrorIndicator = styled(ErrorIcon)`
  position: absolute;
  bottom: 20px;
  right: -25px;
  
  ${(props) => {
    if (props.position) return css(props.position);
    return css``;
  }}
`;

const ErrorCheckboxWrapper = styled('div')`
  &.has-error svg path {
    fill: ${theme.palette.error.main};
  }
`;

export default {
  ErrorWrapper,
  Error,
  ErrorIndicator,
  ErrorCheckboxWrapper,
};
