import React from 'react';
import { Routes, Route } from 'react-router-dom';

// TODO make lazy loading
import LoginContainer from './containers/Login.container';
import Layout from './components/Layout/Layout';
import RequireAuth from './components/RequireAuth/RequireAuth';
import RequireDashboard from './components/RequireDashboard/RequireDashboard';
import Circle from './components/Circle/Circle';

const Overview = React.lazy(() => import('./containers/Overview.container'));
const Registration = React.lazy(() =>
  import('./containers/Registration.container'),
);
const RestorePassword = React.lazy(() =>
  import('./containers/RestorePassword.container'),
);
const DashboardIntro = React.lazy(() =>
  import('./containers/DashboardIntro.container'),
);
const ThankYouCard = React.lazy(() =>
  import('./components/ThankYouCard/index'),
);
const ConfirmEmail = React.lazy(() =>
  import('./components/ConfirmEmail/index'),
);

function SWRoutes() {
  return (
    <Routes>
      <Route element={<RequireAuth />}>
        <Route element={<RequireDashboard />}>
          <Route path="/" element={<Layout />}>
            <Route
              index
              element={
                <React.Suspense fallback={<Circle />}>
                  <Overview />
                </React.Suspense>
              }
            />
            <Route
              path="overview/*"
              element={
                <React.Suspense fallback={<Circle />}>
                  <Overview />
                </React.Suspense>
              }
            />
          </Route>
        </Route>
        <Route
          path="onboarding"
          element={
            <React.Suspense fallback={<Circle />}>
              <DashboardIntro />
            </React.Suspense>
          }
        />
      </Route>
      <Route
        path="registration"
        element={
          <React.Suspense fallback={<Circle />}>
            <Registration />
          </React.Suspense>
        }
      />
      <Route
        path="thank-you"
        element={
          <React.Suspense fallback={<Circle />}>
            <ThankYouCard />
          </React.Suspense>
        }
      />
      <Route path="login" element={<LoginContainer />} />
      <Route
        path="restore-password"
        element={
          <React.Suspense fallback={<Circle />}>
            <RestorePassword />
          </React.Suspense>
        }
      />
      <Route
        path="confirm-email"
        element={
          <React.Suspense fallback={<Circle />}>
            <ConfirmEmail />
          </React.Suspense>
        }
      />
    </Routes>
  );
}

export default SWRoutes;
