import { styled } from '@mui/material';
import { colors } from '../../theme';

const BurgerWrapper = styled('div')(({ theme, ...props }) => ({
  padding: '23px 20px',
  borderRadius: 8,
  backgroundColor: props.open ? colors.smoke : 'transparent',

  [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
    backgroundColor: props.open ? colors.smoke : colors.iron,
  },
}));

const Burger = styled('button')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 24px;
  height: 17px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;
  outline: none;

  span {
    width: 24px;
    height: 3px;
    transition: all 0.3s linear;
    position: relative;
    background: ${colors.black};

    :first-of-type {
      transform: ${({ open }) => (open ? 'translateY(7px) rotate(45deg)' : 'rotate(0)')};
    }

    :nth-of-type(2) {
      opacity: ${({ open }) => (open ? '0' : '1')};
      transform: ${({ open }) => (open ? 'translateX(20px)' : 'translateX(0)')};
    }

    :nth-of-type(3) {
      transform: ${({ open }) => (open ? 'translateY(-7px) rotate(-45deg)' : 'rotate(0)')};
    }
  }
`;

export default {
  BurgerWrapper,
  Burger,
};
