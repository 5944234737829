import React, { useCallback, useMemo, useReducer } from 'react';
import { OVERVIEW_INITIAL_DATA } from '../constants';
import overviewReducer, { actionTypes } from '../reducers/overview.reducer';
import API_SERVICE from '../services/api';
import {
  ALLOCATION_VALUES,
  BALANCE_AND_DEPOSITS,
  CLASSES_VALUES,
  GEO_VALUES,
  HISTORY_BENCHMARK_VALUES,
  PORTFOLIO_DETAILED,
  TRANSACTIONS,
} from '../connectors';

export const OverviewContext = React.createContext({
  overviewData: {},
  getAllData: () => {},
  getBalanceAndDeposits: () => {},
  getHistoryChartData: () => {},
  getGeoChartData: () => {},
  getClassesChartData: () => {},
  getPortfolioDetailedData: () => {},
  getRiskProfiles: () => {},
});

export default function OverviewProvider({ children }) {
  const [overviewData, dispatch] = useReducer(
    overviewReducer,
    OVERVIEW_INITIAL_DATA,
  );

  // TODO refactor / optimize data imports (Promise.all)

  const handleGetBalanceAndDeposits = useCallback(() => {
    API_SERVICE.balanceAndDeposits().then((response) => {
      dispatch({
        type: actionTypes.SET_OVERVIEW_DATA,
        payload: BALANCE_AND_DEPOSITS(response.data.data[0]),
      });
    });
  }, []);

  const handleGetHistoryChartData = useCallback(() => {
    Promise.all([
      API_SERVICE.historicalPerformanceBenchmark(),
      API_SERVICE.allocationByFinancialInstrument(),
    ]).then(([benchmarkResponse, allocationResponse]) => {
      const benchmarkData = HISTORY_BENCHMARK_VALUES(
        benchmarkResponse.data.data,
      );
      const allocationData = ALLOCATION_VALUES(allocationResponse.data.data);

      dispatch({
        type: actionTypes.SET_OVERVIEW_DATA,
        payload: {
          history_benchmark: benchmarkData,
          history_allocation: allocationData,
        },
      });
    });
  }, []);

  const handleGetGeoChartData = useCallback(() => {
    API_SERVICE.concentrationRegionAllocation().then((response) => {
      const { data: geo } = response.data;

      dispatch({
        type: actionTypes.SET_OVERVIEW_DATA,
        payload: { geo: GEO_VALUES(geo) },
      });
    });
  }, []);

  const handleGetClassesChartData = useCallback(() => {
    API_SERVICE.concentrationAssetClass().then((response) => {
      const { data: classes } = response.data;

      dispatch({
        type: actionTypes.SET_OVERVIEW_DATA,
        payload: { classes: CLASSES_VALUES(classes) },
      });
    });
  }, []);

  const handleGetPortfolioDetailedData = useCallback(() => {
    API_SERVICE.concentrationHoldings().then((response) => {
      const { data: portfolioDetailedData } = response.data;

      dispatch({
        type: actionTypes.SET_OVERVIEW_DATA,
        payload: {
          portfolio_detailed: PORTFOLIO_DETAILED(portfolioDetailedData),
        },
      });
    });
  }, []);

  const handleGetRiskProfiles = useCallback(() => {
    API_SERVICE.riskProfiles().then((response) => {
      const { data: riskProfiles } = response.data;
      dispatch({
        type: actionTypes.SET_OVERVIEW_DATA,
        payload: {
          risk_profiles: riskProfiles,
        },
      });
    });
  }, []);

  const handleGetTransactionsData = useCallback(() => {
    API_SERVICE.stmtFunds().then((response) => {
      const { data: txs } = response.data;

      dispatch({
        type: actionTypes.SET_OVERVIEW_DATA,
        payload: {
          transactions: TRANSACTIONS(txs),
        },
      });
    });
  }, []);

  const handleGetAllData = useCallback(() => {
    handleGetBalanceAndDeposits();
    handleGetHistoryChartData();
    handleGetGeoChartData();
    handleGetClassesChartData();
    handleGetPortfolioDetailedData();
    handleGetRiskProfiles();
    handleGetTransactionsData();
  }, [
    handleGetBalanceAndDeposits,
    handleGetClassesChartData,
    handleGetGeoChartData,
    handleGetHistoryChartData,
    handleGetPortfolioDetailedData,
    handleGetRiskProfiles,
    handleGetTransactionsData,
  ]);

  const overviewValue = useMemo(
    () => ({
      overviewData,
      getAllData: handleGetAllData,
      getBalanceAndDeposits: handleGetBalanceAndDeposits,
      getHistoryChartData: handleGetHistoryChartData,
      getGeoChartData: handleGetGeoChartData,
      getClassesChartData: handleGetClassesChartData,
      getPortfolioDetailedData: handleGetPortfolioDetailedData,
      getRiskProfiles: handleGetRiskProfiles,
      getTransactionsData: handleGetTransactionsData,
    }),
    [
      overviewData,
      handleGetAllData,
      handleGetBalanceAndDeposits,
      handleGetHistoryChartData,
      handleGetGeoChartData,
      handleGetClassesChartData,
      handleGetPortfolioDetailedData,
      handleGetRiskProfiles,
      handleGetTransactionsData,
    ],
  );

  return (
    <OverviewContext.Provider value={overviewValue}>
      {children}
    </OverviewContext.Provider>
  );
}

export function useOverviewContext() {
  const overviewContext = React.useContext(OverviewContext);
  if (!overviewContext) {
    throw new Error(
      'useOverviewContext must be used within a OverviewProvider',
    );
  }
  return overviewContext;
}
