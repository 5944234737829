import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

const useOnLocationChange = (handleLocationChange) => {
  const location = useLocation();

  return useMemo(() => handleLocationChange(location), [location, handleLocationChange]);
};

export default useOnLocationChange;
