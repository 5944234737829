import axios from 'axios';
import { toast } from 'react-toastify';
import URL from './urls';
import UTILS from './utils';

class API {
  constructor() {
    this.instance = axios.create({
      baseURL: "https://dashboard.simplewealth.ch/api/",
      withCredentials: true,
    });

    this.instance.interceptors.request.use(async (config) => {
      const token =
        localStorage.getItem('token') || sessionStorage.getItem('token');

      config.headers.authorization = `Bearer ${token}`;
      config.timeout = 60 * 4 * 1000;

      return config;
    });

    this.instance.interceptors.response.use(
      (response) => response,
      (error) => {
        const serverErrorMessage = UTILS.getServerErrorMessage(error);
        toast.error(serverErrorMessage || error.message);
      },
    );
  }

  login = (data) => this.instance.post(URL.LOGIN, data);

  register = (data) => this.instance.post(URL.REGISTER, data);

  recoverPassword = (data) => this.instance.post(URL.RECOVER_PASSWORD, data);

  verificateEmail = (token) =>
    this.instance.get(`${URL.VERIFICATION_EMAIL}?token=${token}`);

  currentUser = () => this.instance.get(URL.CURRENT_USER);

  balanceAndDeposits = () => this.instance.get(URL.BALANCE_AND_DEPOSITS);

  historicalPerformanceBenchmark = () =>
    this.instance.get(URL.HISTORICAL_PERFORMANCE_BENCHMARK);

  allocationByFinancialInstrument = () =>
    this.instance.get(URL.ALLOCATION_BY_FINANCIAL_INSTRUMENT);

  concentrationRegionAllocation = () =>
    this.instance.get(URL.CONCENTRATION_REGION_ALLOCATION);

  concentrationAssetClass = () =>
    this.instance.get(URL.CONCENTRATION_ASSET_CLASS);

  concentrationHoldings = () => this.instance.get(URL.CONCENTRATION_HOLDINGS);

  riskProfiles = () => this.instance.get(URL.RISK_PROFILES);

  stmtFunds = () => this.instance.get(URL.STMT_FUNDS);
}

export const getOnboardingComplited = (onboardingID) =>
  axios.get(
    `https://onboarding-api.simplewealth.ch/prod/onboarding/${onboardingID}`,
  );

const API_SERVICE = new API();

export default API_SERVICE;
