import React, { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Login from '../components/Login';
import API_SERVICE from '../services/api';
import useDocumentTitle from '../hooks/useDocumentTitle';
import { useAuthorization } from '../contexts';

function LoginContainer() {
  useDocumentTitle('Login');
  const { setAuth } = useAuthorization();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || '/';

  const handleSubmit = useCallback(
    async (values) => {
      const response = (await API_SERVICE.login(values)) || {};
      if ([200, 201].includes(response.status) && response.data) {
        setAuth(true);
        navigate(from, { replace: true });
      }
    },
    [setAuth, navigate, from],
  );

  return <Login handleSubmit={handleSubmit} />;
}

export default LoginContainer;
