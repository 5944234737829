import React from 'react';
import { Field } from 'react-final-form';
import { string } from 'prop-types';
import clsx from 'clsx';
import TextInput from '../Input/TextInput';
import S from './styled';

function FTextInput(props) {
  return (
    <Field name={props.name}>
      {({ input, meta }) => {
        const hasError = Boolean(meta.touched && meta.error);
        const inputClasses = clsx(props.className, {
          'has-error': hasError,
        });

        return (
          <S.ErrorWrapper>
            <TextInput
              {...props}
              className={inputClasses}
              inputProps={{
                ...input,
                ...props.inputProps,
                error: hasError,
              }}
            />
            {hasError && <S.Error>{meta.error}</S.Error>}
            {hasError && <S.ErrorIndicator />}
          </S.ErrorWrapper>
        );
      }}
    </Field>
  );
}

FTextInput.propTypes = {
  name: string.isRequired,
};

export default FTextInput;
