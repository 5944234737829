import { transformToDate } from '../utils';

export const REGISTER_VALUES = (values, destination = 'BE') => {
  if (destination === 'BE') {
    return {
      fullName: values.full_name,
      promoCode: values.promo_code,
      email: values.email,
      password: values.password,
    };
  }

  return {
    promo_code: values.promoCode,
    full_name: values.fullName,
    email: values.email,
    password: values.password,
  };
};

export function CURRENT_USER(values) {
  return {
    full_name: values.fullName,
    risk_profile_id: values.riskProfileId,
    goal: values.goal,
    duration: values.duration,
    IBUID: values?.IBUID || null,
    onboardingID: values?.onboardingID || null,
  };
}

export const BALANCE_AND_DEPOSITS = (values) => ({
  balance: {
    ending_nav: values.EndingNAV,
    cumulative_return: values.CumulativeReturn,
  },
  deposits_withdrawals: values['Deposits/Withdrawals'],
});

export const HISTORY_BENCHMARK_VALUES = (values) => {
  const hasMissingData = (item) => !item.Month || !item.AccountReturn;
  return values.reduce((acc, next) => {
    if (hasMissingData(next)) return acc;
    acc[transformToDate(next.Month)] = {
      account_return:
        next.AccountReturn === '-' ? 0 : Number.parseFloat(next.AccountReturn),
    };

    return acc;
  }, {});
};

export const ALLOCATION_VALUES = (values) =>
  values.reduce((acc, next) => {
    acc[transformToDate(next.Date)] = {
      nav: Number.parseFloat(next.NAV),
    };
    return acc;
  }, {});

export const GEO_VALUES = (values) =>
  values.map((item) => ({
    region: item.Region,
    long_parsed_weight: item.LongParsedWeight,
  }));

export const CLASSES_VALUES = (values) =>
  values.map((item) => ({
    asset_class: item.AssetClass,
    long_parsed_weight: item.LongParsedWeight,
  }));

export const PORTFOLIO_DETAILED = (values) => {
  const transformValue = (item) => ({
    symbol: item.Symbol,
    description: item.Description,
    sector: item.Sector,
    net_value: item.NetValue,
    net_parsed_weight: item.NetParsedWeight,
    sub_rows: [],
  });
  return values.reduce((acc, next) => {
    if (next.Symbol === 'Total') return acc;
    if (!next.Symbol.startsWith('    ')) {
      acc.push(transformValue(next));
      return acc;
    }
    acc[acc.length - 1].sub_rows.push(transformValue(next));
    return acc;
  }, []);
};

export const TRANSACTIONS = (values) =>
  values.map((item) => ({
    description: item.activityDescription,
    amount: Number.parseFloat(item.amount),
    balance: Number.parseFloat(item.balance),
  }));
