import React from 'react';
import { bool, func } from 'prop-types';
import S from './Burger.styled';

function Burger({ open, toggleMenu, ...props }) {
  return (
    <S.BurgerWrapper open={open}>
      <S.Burger
        aria-label="Toggle menu"
        aria-expanded={open}
        open={open}
        onClick={toggleMenu}
        {...props}
      >
        <span />
        <span />
        <span />
      </S.Burger>
    </S.BurgerWrapper>
  );
}

Burger.propTypes = {
  open: bool.isRequired,
  toggleMenu: func.isRequired,
};

export default Burger;
