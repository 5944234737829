import React from 'react';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
// import SidebarMenuButton from '../Burger';
import { ReactComponent as Logo } from '../../assets/icons/logo.svg';
import S from './styled';
import SidebarNavMenu from './SidebarNavMenu';
// import Navigation from './Navigation';
import { FONTS } from '../../constants';

const useStyles = makeStyles((theme) => ({
  logout: {
    height: 44,
    width: 99,
    font: `normal normal 400 16px/20px ${FONTS}`,
  },
  user: {
    font: `normal normal 400 16px/20px ${FONTS}`,
    marginRight: 32,
    color: theme.palette.text.primary,
  },
}));

function AuthHeader({
  isSidebarOpen,
  toggleSidebar,
  navigateToSignIn,
  // navigateToSignUp,
  user,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.tablet),
  );
  // const isDesktop = useMediaQuery(theme.breakpoints.up(theme.breakpoints.values.desktop));

  // const navigateAndCloseSidebar = useCallback(
  //   (navigateFn) => () => {
  //     navigateFn();
  //     toggleSidebar();
  //   },
  //   [toggleSidebar],
  // );

  if (!isMobile) {
    return (
      <S.Header>
        <S.Content mobile={isMobile}>
          <S.Left mobile={isMobile}>
            {/* <SidebarMenuButton open={isSidebarOpen} toggleMenu={toggleSidebar} /> */}
            <a href="./" rel="noopener noreferrer">
              <Logo />
            </a>
          </S.Left>
          {/* <SidebarNavMenu
            open={isSidebarOpen}
            navigateToSignIn={navigateAndCloseSidebar(navigateToSignIn)}
            navigateToSignUp={navigateAndCloseSidebar(navigateToSignUp)}
            onLinkClick={toggleSidebar}
            mobile
          /> */}
          <div>
            <span className={classes.user}>{user && user.full_name}</span>
            <Button
              color="info"
              variant="contained"
              onClick={navigateToSignIn}
              className={classes.logout}
            >
              Logout
            </Button>
          </div>

          <SidebarNavMenu open={isSidebarOpen} onLinkClick={toggleSidebar} />
        </S.Content>
      </S.Header>
    );
  }

  return (
    <S.Header>
      <S.Content>
        <S.Left>
          {/* <SidebarMenuButton open={isSidebarOpen} toggleMenu={toggleSidebar} /> */}
          <a href="./" rel="noopener noreferrer">
              <Logo />
            </a>
        </S.Left>

        {/* {isDesktop && <Navigation />} */}
        <div>
          <span className={classes.user}>{user && user.full_name}</span>
          <Button
            color="info"
            variant="contained"
            onClick={navigateToSignIn}
            className={classes.logout}
          >
            Logout
          </Button>
        </div>

        <SidebarNavMenu open={isSidebarOpen} onLinkClick={toggleSidebar} />
      </S.Content>
    </S.Header>
  );
}

export default AuthHeader;
