import React from 'react';
import { CircularProgress } from '@mui/material';
import S from './styled';

function Circle() {
  return (
    <S.Wrapper>
      <CircularProgress />
    </S.Wrapper>
  );
}

export default Circle;
