import { styled } from '@mui/material';
import theme from '../../theme';

const Background = styled('div')`
  background-color: ${(props) => props.color || theme.palette.background.default};
  height: auto;
`;

export default {
  Background,
};
