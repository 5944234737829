import { styled } from '@mui/material';
import { Link } from 'react-router-dom';
import { colors } from '../../theme';
import { FONTS, MAX_WIDTH } from '../../constants';

const Wrapper = styled('section')`
  background-color: ${colors.white};
`;

const Content = styled('div')`
  max-width: ${MAX_WIDTH}px;
  height: 100%;
  margin: auto;

  display: grid;
  grid-template-columns: repeat(6, 192px);
  column-gap: 32px;

  padding: 66px 63px 50px 65px;
`;

const linkStyles = () => ({
  textDecoration: 'none',
  display: 'block',
  color: colors.cobalt,
  font: `normal normal 400 16px/20px ${FONTS}`,
  cursor: 'pointer',

  '&:hover': {
    color: colors.black,
  },
});

const FakeLink = styled('span')(linkStyles);

const NavigationLink = styled(Link)(linkStyles);

const ExternalNavigationLink = styled('a')(linkStyles);

export default {
  Wrapper,
  NavigationLink,
  ExternalNavigationLink,
  FakeLink,
  Content,
};
